<template>
  <section class="home-section">
    <div class="home-carousel">
      <div v-if="banners.length > 1">
        <swiper
          :pagination="pagination"
          :modules="modules"
          :navigation="true"
          class="mySwiper"
          :loop="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }"
        >
          <swiper-slide
            class="home-item"
            v-for="slider in banners"
            :key="slider.id"
          >
            <img class="home-img" :src="slider.image" alt="" />
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="home-content">
                    <p class="home-desc">
                      {{ slider.details }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <a
            class="main-btn lg up mybtn"
            :data-bs-toggle="isToken ? 'modal' : ''"
            :data-bs-target="isToken ? '#imgModal' : ''"
            @click="preventLogin"
          >
            {{ baner_text }}
          </a>
        </swiper>
      </div>
      <div v-else-if="banners.length === 1">
        <div class="single-image">
          <img class="home-img" :src="banners[0].image" alt="" />
        </div>
        <a
          class="main-btn lg up mybtn"
          :data-bs-toggle="isToken ? 'modal' : ''"
          :data-bs-target="isToken ? '#imgModal' : ''"
          @click="preventLogin"
        >
          {{ baner_text }}
        </a>
      </div>
      <div v-else>
        <div class="single-image" style="border: 5px solid cadetblue; border-radius: 5px; width: 100%; height: 500px;">
          <!-- Placeholder if no banners -->
        </div>
        <a
          class="main-btn lg up mybtn"
          :data-bs-toggle="isToken ? 'modal' : ''"
          :data-bs-target="isToken ? '#imgModal' : ''"
          @click="preventLogin"
        >
          {{ baner_text }}
        </a>
      </div>
    </div>
  </section>

  <addAdsPanner />
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import addAdsPanner from "../ads/addAdsPanner.vue";
import axios from "axios";

export default {
  data() {
    return {
      baner_text: "",
      isToken: window.localStorage.getItem('token') ? true : false,
    };
  },
  methods: {
    preventLogin() {
      if (!this.isToken) {
        this.$swal({
          icon: "error",
          title: "قم بتسجيل الدخول اولا",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    async getHomeLogo() {
      await axios
        .get("home", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.baner_text = res.data.data.baner_text;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  props: {
    banners: Array,
  },
  mounted() {
    this.getHomeLogo();
  },
  components: {
    Swiper,
    SwiperSlide,
    addAdsPanner,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
      },
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style scoped>
/* General reset and adjustments */
a {
  text-decoration: none !important;
}

/* Button adjustments */
.main-btn.lg {
  /* transform: translate(-22%, -50px);
  position: relative;
  z-index: 10;
  margin-top: -50px; adjust for better visibility */
  position: absolute;
  z-index: 10;
  inset-block-end: 0px;
  inset-inline-start: 0px;
}

/* Swiper styling adjustments */
.home-carousel .home-item {
  position: relative;
}

.home-img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure images cover the area without distortion */
}

.home-section {
  width: 100%;
}

.single-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsive design */
@media (max-width: 768px) {
  /* Adjusting the Swiper for smaller screens */
  .swiper {
    height: auto; /* Remove fixed height to make images responsive */
  }

  .home-content {
    font-size: 14px; /* Reduce font size for small screens */
  }

  /* Adjusting the button for smaller screens */
  .main-btn.lg {
    /* transform: translate(-50%, -100%); */
    /* margin-top: 0px; */
    inset-block-end: 0;
    inset-inline-start: 0;
  }

  .col-lg-6 {
    width: 100%; /* Make the content take full width on smaller screens */
  }

  /* Adjust the height of images for better viewing on mobile */
  .single-image {
    height: 300px; /* Decrease height on mobile */
  }
}

@media (max-width: 480px) {
  .home-content {
    font-size: 12px; /* Make text even smaller for very small screens */
  }

  .main-btn.lg {
    /* transform: translate(-50%, -120%); */
    /* font-size: 12px; Adjust button size on very small screens */
  }

  /* Adjust the images further for smaller viewports */
  .home-img {
    object-fit: contain; /* Ensure images are not cropped on very small screens */
  }
}
</style>
