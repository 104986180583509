<template>
  <loader v-if="loader" />
  <div class="add-ads main-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 mx-auto">
          <div class="add-form section-style sec-padding">
            <div class="row">
              <div class="col-lg-8 col-md-10 col-11 mx-auto">
                <!-- form  -->
                <form ref="uploadAdForm" enctype="multipart/form-data">
                  <h2 class="section-title">ارفع إعلانك</h2>
                  <div class="inputs-container">
                    <div class="upload-ads-img">
                      <span class="ads-img-text">
                        ارفع صور الإعلان
                        <span class="upload-text">بحد اقصى 6 صور</span>
                      </span>
                      <div class="upload-img-container">
                        <div class="upload-img">
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            id="imgUpload"
                            name="image[]"
                            class="hidden-input places-imgs-input"
                            @change="uploadAdImages($event.target)"
                          />
                          <div class="img-upload-show">
                            <label for="imgUpload" class="label-img">
                              <img
                                :src="camImage"
                                alt=""
                                v-bind:ref="'image' + parseInt(key)"
                              />
                            </label>
                          </div>
                        </div>

                        <div class="img-upload-show mt-4">
                          <div class="d-flex">
                            <div
                              class="hidden-img"
                              v-if="adsImages.length <= 6"
                            >
                              <div
                                class="position-relative"
                                v-for="image in adsImages"
                                :key="image.id"
                              >
                                <button
                                  class="remove-img text-white"
                                  type="button"
                                  @click="removeImage(image.id)"
                                >
                                  &times;
                                </button>
                                <img
                                  class="preview img-thumbnail"
                                  :src="image.url"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="showValid" class="imageAlert">
                            <v-alert type="error">
                              غير مسموح بأكثر من 6 صور
                            </v-alert>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> اسم الإعلان </label>
                      <div class="main-input">
                        <input
                          type="text"
                          class="input-me"
                          placeholder="أدخل اسم الإعلان"
                          name="name"
                          v-model="name"
                        />
                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> سعر الإعلان </label>
                      <div class="main-input">
                        <input
                          type="number"
                          class="input-me"
                          placeholder="أدخل سعر الإعلان"
                          name="price"
                          v-model="price"
                        />
                      </div>
                    </div>
                    <!-- <div class="input-g">
                      <label for="" class="main-label"> مدة الإعلان (المدة بالايام) </label>
                      <div class="main-input">
                        <input
                          type="number"
                          class="input-me"
                          placeholder="أدخل مدة عرض الإعلان"
                          name="duration"
                          v-model="duration"
                        />
                      </div>
                    </div> -->
                    <div class="input-g">
                      <label for="" class="main-label"> القسم الرئيسي </label>
                      <div class="main-input">
                        <select
                          name="category_id"
                          id=""
                          class="input-me select"
                          @change="getCatId()"
                          v-model="category_id"
                        >
                          <option value="" selected disabled hidden>
                            اختر القسم الرئيسي
                          </option>
                          <option
                            :value="cat.id"
                            v-for="cat in categories"
                            :key="cat.id"
                          >
                            {{ cat.name }}
                          </option>
                        </select>
                        <i class="fa-solid fa-angle-down main-icon"></i>
                      </div>
                    </div>
                    <!-- المدينة -->
                    <div class="input-g">
                      <label for="" class="main-label">المدينة</label>
                      <div class="main-input">
                        <select
                          name="city_id"
                          id="city_select"
                          class="input-me select"
                          v-model="city_id"
                        >
                          <option value="" selected disabled hidden>
                            اختر المدينة
                          </option>
                          <option
                            :value="city.id"
                            v-for="city in cities"
                            :key="city.id"
                          >
                            {{ city.name }}
                          </option>
                        </select>
                        <i class="fa-solid fa-angle-down main-icon"></i>
                      </div>
                      <div>
                        <span>اذا لم تجدالمدينه</span>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#addCity"
                          type="button"
                          style="color: #2abdc7"
                        >
                          اضغط هنا
                        </button>
                      </div>
                    </div>
                    <div class="input-g">
                      <label for="" class="main-label"> تفاصيل الإعلان </label>
                      <div class="main-input">
                        <textarea
                          name="details"
                          v-model="details"
                          class="input-me text-area"
                          placeholder="اكتب تفاصيل الإعلان"
                        ></textarea>
                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> وسيلة اتصال </label>
                      <div class="main-input">
                        <input
                          name="contact_method"
                          v-model="contact_method"
                          type="text"
                          class="input-me"
                          placeholder="أدخل رقم الجوال أو البريد الالكتروني"
                        />
                      </div>
                    </div>

                    <div class="input-g">
                      <label for="" class="main-label"> حالة المنتج </label>
                      <div class="radio-boxs">
                        <div class="box">
                          <input
                            type="radio"
                            name="status"
                            v-model="status"
                            value="new"
                            id="da3ef"
                          />
                          <label for="da3ef">جديد</label>
                        </div>
                        <div class="box">
                          <input
                            type="radio"
                            name="status"
                            v-model="status"
                            value="used"
                            id="maqbool"
                          />
                          <label for="maqbool">مستعمل</label>
                        </div>
                      </div>
                    </div>

                    <div class="input-g">
                      <button
                        type="submit"
                        class="main-btn md up mx-auto"
                        :disabled="disabled"
                        @click.prevent="uploadAdv()"
                      >
                        استمرار
                        <div
                          class="spinner-border"
                          role="status"
                          v-if="disabled"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="addCity"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="close-model-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa-regular fa-circle-xmark"></i>
        </button>

        <div class="content-model-me">
          <div class="modal-header">
            <h2 class="section-title">إضافة مدينة</h2>
          </div>

          <form action="" class="modal-form" ref="sendCode">
            <div class="modal-body">
              <div class="inputs-container">
                <div class="input-g">
                  <label for="" class="main-label"> اسم المدينة </label>
                  <div class="main-input">
                    <input
                      type="text"
                      class="input-me"
                      placeholder="أدخل اسم المدينة"
                      name="city_name"
                      v-model="city_name"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button class="main-btn md up blabla" @click.prevent="addNewCity">
                إرسال
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "@/components/Shared/pageLoader.vue";

export default {
  data() {
    return {
      camImage: require("@/assets/imgs/photo-camera.png"),
      // form inputs
      status: "",
      contact_method: "",
      details: "",
      city: "",
      sub_category_id: "",
      category_id: "",
      duration: "",
      price: "",
      name: "",
      storage_url:
        "https://ebtkarr.com/dashboard/public/storage/images/listImages/",
      imags: [],
      loader: true,
      addCity: false,

      // images previewed
      adsImages: [],
      adsImagesName: [],
      showValid: false,

      // get categories
      categories: [],
      // get sub_category_id
      subCategories: [],

      // cities
      cities: [],
      city_id: null,

      // loading pagination handle
      regionQuery: null,

      disabled: false,
      city_name: "",
    };
  },

  computed: {
    filteredRegions() {
      return this.cities.filter((region) => {
        return region.name
          .toLowerCase()
          .includes(this.regionQuery.toLowerCase());
      });
    },
  },
  components: {
    loader,
  },
  methods: {
    async addNewCity() {
      const fd = new FormData();
      fd.append("name", this.city_name);
      await axios
        .post("new-city", fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data?.key == "success") {
            this.$swal({
              icon: "success",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
            document.getElementById("addCity").style.display = "none";
            document.querySelector(".modal-backdrop").style.display = "none";
            setTimeout(() => {
              this.getCities().then(() => {});
            }, 1000);
          } else {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
          }
        });
    },
    // image preview
    uploadAdImages(file) {
      // Handle the uploaded files
      let selectedImages = file.files;

      // Iterate through all selected images and add them to the array
      for (let i = 0; i < selectedImages.length; i++) {
        this.adsImages.push({
          url: URL.createObjectURL(selectedImages[i]),  // Create a local URL for image preview
          id: Date.now() + i,  // Generate a unique ID for each image (temporary for local preview)
          file: selectedImages[i]  // Store the file for upload
        });
      }

      // Check if the number of images exceeds the limit
      if (this.adsImages.length > 6) {
        this.adsImages = this.adsImages.slice(0, 6);  // Limit to 6 images
        this.showValid = true;
      } else {
        this.showValid = false;
      }

      // Trigger image preview update
      this.applyImage();
    },

    // remvoe image
    removeImage(id) {
      console.log(id);

      // Filter the adsImages array to remove the image with the given id
      this.adsImages = this.adsImages.filter(item => item.id !== id);

      // After updating the array, you might also want to make an API call to delete the image
      axios.post(`delete-advertisementImage/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((res) => {
        if (res.data.key === 'success') {
          this.$swal({
            icon: "success",
            title: res.data.msg,
            timer: 2000,
            showConfirmButton: false,
          });
          // Optionally reload or update the UI after image removal
          // setTimeout(() => {
          //   location.reload();
          // }, 1000);
        }
      });

      // Optionally call this.applyImage() if you need to update image previews after removal
      this.applyImage();
    },

    applyImage() {
      for (let i = 0; i < this.adsImages.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs.image[i].src = reader.result;
        };
        reader.readAsDataURL(this.adsImages[i]);
      }
    },

    // submit upload ads form
    async uploadAdv() {
      this.disabled = true;
      let fd = new FormData(this.$refs.uploadAdForm);
      fd.append("name", this.name);
      fd.append("price", this.price);
      // fd.append("duration", this.duration);
      fd.append("status", this.status);
      fd.append("contact_method", this.contact_method);
      fd.append("details", this.details);
      fd.append("sub_category_id", this.sub_category_id);
      fd.append("category_id", this.category_id);
      fd.append("city_id", this.city_id);
      await axios
        .post(`update-advertisement/${this.$route.params.id}`, fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.key == "success") {
            this.$swal({
              icon: "success",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
            this.$router.push("/");
          } else if (res.data.key == "needReActivation") {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });

            setTimeout(() => {
              this.$router.push("/editProfileView");
            }, 2000);
          } else {
            this.$swal({
              icon: "error",
              title: res.data.msg,
              timer: 2000,
              showConfirmButton: false,
            });
          }
          this.disabled = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // get categories to filter
    async getCategories() {
      await axios.get("categories").then((res) => {
        this.categories = res.data.data;
      });
    },

    // get subCategories
    async getCatId() {
      console.log(this.category_id);
      await axios.get(`categories/${this.category_id}`).then((res) => {
        this.subCategories = res.data.data;
      });
    },

    // get cities
    async getCities() {
      this.showList1 = true;
      await axios.get("cities").then((res) => {
        this.cities = res.data.data;
      });
    },

    setNewValueForRegion(value1, id) {
      this.regionQuery = value1;
      this.city_id = id;
      if (this.regionQuery == value1) {
        this.showList1 = false;
      }
      // console.log(this.regionQuery)

      // this.getStores()
    },
    get_single_ad() {
      axios
        .get(`edit-advertisement/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          const image_keys = Object.keys(res.data.data.advertisement.image);

          image_keys.map((imageID) => {
            // Create an object for each image with both URL and the associated ID value
            const imageObject = {
              url: res.data.data.advertisement.image[0].images,
              id: imageID
            };
            
            // Push the object into the adsImages array
            this.adsImages.push(imageObject);
            return imageObject;
          });

          this.name = res.data.data.advertisement.name;
          this.price = res.data.data.advertisement.price;
          this.duration = res.data.data.advertisement.duration;
          this.status = res.data.data.advertisement.status;
          this.city_id = res.data.data.advertisement.city_id;
          this.category_id = res.data.data.advertisement.category_id;
          this.details = res.data.data.advertisement.details;
          this.contact_method = res.data.data.advertisement.contact_method;
        });
    },
  },
  mounted() {
    this.getCategories();
    this.getCities();
    this.get_single_ad();
    this.loader = false;
  },
};
</script>

<style>
.imageAlert {
  color: red;
  display: flex;
}
.addCity {
  color: #2abdc7;
  cursor: pointer;
}
</style>
